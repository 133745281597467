import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/svg.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { FaXTwitter } from "react-icons/fa6";

export const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer" id="footer">

      <div className="ribbon-element">
        <hr/>
        <div className="ribbon-container">
        <p>Portfolio 2025-2026</p>
        <p>Available for freelance work </p>
       
        </div>
 <hr/>
      </div>
      <div className="middle-footer-element">
        <h1>Let's work <br></br> Together</h1>
       
       <div className="email-container">
      <span>Drop me an email: </span>
       <b>info@roarz-codes.com</b>
        
     
       </div>
 </div>
      <div className="last-footer-element">
        <Row className="pt-3 footer-element align-items-center">
          <Col size={12} sm={6} className="copyright-container">
          <p>&copy; {currentYear}. All Rights Reserved</p>
          </Col>
          <Col size={12} sm={6} className="text-center footer-social-icons text-sm-end">
            <div className="social-icon">
            <a href="https://www.instagram.com/roarz_codes/profilecard/?igsh=MXVnZWllbGZ3c3k1dw=="><img src={navIcon3} alt="Icon" /></a>

              <a href="https://x.com/fx__matrix?s=09"><FaXTwitter className="social-icon"/></a>
              <a href="https://ke.linkedin.com/in/eugene-roarz-4869b82b1"><img src={navIcon1} alt="Icon" /></a>

            </div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}
